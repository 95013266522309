import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { UserProfile } from '../model/userProfile'
import { authorizedFetch } from '../model/auth-helpers'
import { useSession } from 'next-auth/react'

export type UserProfileContextType = {
  userProfile: UserProfile | undefined
}

export const UserProfileContext = createContext<UserProfileContextType | null>(
  null
)

interface IProps {
  children: ReactNode
}

const UserProfileProvider: React.FC<IProps> = ({ children }) => {
  const { data: session } = useSession()

  const [userProfile, setUserProfile] = useState<UserProfile>()

  const loadUserProfile = () => {
    if (!session) {
      return
    }

    const url = `${process.env.NEXT_PUBLIC_API_URL}/api/profile`

    authorizedFetch(url, {}, session)
      .then((res) => res?.json())
      .then((data: UserProfile) => {
        setUserProfile(data)
      })
  }
  useEffect(loadUserProfile, [session])

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  )
}

const useUserProfile = (): UserProfileContextType => {
  const context = useContext(UserProfileContext)
  if (!context) {
    throw new Error('useUserProfile must be used within a UserProfileProvider')
  }
  return context
}

export { UserProfileProvider, useUserProfile }
