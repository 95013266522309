import '../styles/globals.css'
import type {AppProps} from 'next/app'
import { SessionProvider, signIn, useSession } from 'next-auth/react'
import {UserProfileProvider} from '../components/userprofile-provider'
import { useEffect } from 'react'
import { NextComponentType, NextPageContext } from 'next'
import PPHead from '../components/head/head'
import StackedLayout from '../components/layout/stacked-layout'
import SpinnerFullPage from '../components/shared/spinner/spinner-full-page'

/**
 * Authentication configuration
 */
export interface AuthEnabledComponentConfig {
  authenticationEnabled: boolean;
}

type AppAuthProps = AppProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: NextComponentType<NextPageContext, any> & Partial<AuthEnabledComponentConfig>;
};

function App({ Component, pageProps }: AppAuthProps) {
  return (
      <SessionProvider session={pageProps.session}>
          <Auth>
            <UserProfileProvider>
              <Component {...pageProps} />
            </UserProfileProvider>
          </Auth>
      </SessionProvider>
  );
};

// @ts-ignore
function Auth({ children }) {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status } = useSession()

  useEffect(() => {
    if (status === 'loading') return   // Do nothing while loading
  }, [status])

  if (status === "loading") {
    return <></>
  }


  return children

}

export default App;

