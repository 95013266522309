import { Session } from 'next-auth'
import { auth } from "../auth"

export async function authorizedFetch(url: any, options: any, session: Session | null) {

  if (!session) {
    console.log('Session not found')
    return
  }

  // @ts-ignore
  const accesstoken = session.accessToken

  const headers = {
    ...options.headers,
    Authorization: `Bearer ${accesstoken}`,
  }

  return fetch(url, { ...options, headers })
}

export async function authorizedPost(url: any, body: any, session: Session | null) {

  if (!session) {
    console.log('Session not found')
    return
  }

  // @ts-ignore
  const accesstoken = session.accessToken

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accesstoken}`,
  }

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body), headers,
  })
}


export async function authorizedDelete(url: any, session: Session | null) {

  if (!session) {
    console.log('Session not found')
    return
  }

  // @ts-ignore
  const accesstoken = session.accessToken

  const headers = {
    Authorization: `Bearer ${accesstoken}`,
  }

  return fetch(url, { method: 'DELETE', headers })
}
